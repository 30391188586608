import React, { useEffect, useState } from 'react'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import Layout from 'src/layouts/BaseLayout'
import { SnackBarProvider } from '@interco/inter-ui/components/SnackBar'
import { CanalAtendimento } from './sections/CanalAtendimento/_index'
import { Hero } from './sections/Hero/_index'

import pageContext from './pageContext.json'
import Validation from './sections/Validation/_validation'
import { initTheme, Theme } from '@interco/inter-ui'

const AtendimentoEmLibras = () => {
  const [ isOpenValidation, setIsOpenValidation ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Atendimento em Libras',
    element_action: 'click button',
    element_name: 'Acessar videochamada em Libras',
  })

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

    return (
      <Layout pageContext={pageContext}>
        {
          isOpenValidation && (
            <SnackBarProvider>
              <Validation />
            </SnackBarProvider>
          )
        }
        { !isOpenValidation && (
          <>
            <Hero onCtaClick={() => { setIsOpenValidation(true) }} dataLayer={dataLayer} />
            <CanalAtendimento onCtaClick={() => setIsOpenValidation(true)} setDataLayer={setDataLayer} />
          </>
          )
        }
      </Layout>

    )
}

export default AtendimentoEmLibras

import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { Section } from './styles'

type HeroProps = {
  onCtaClick: () => void;
  dataLayer: IDataLayerParams;
}

export const Hero = ({ onCtaClick, dataLayer }: HeroProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mb-3'>
              Atendimento em Libras
            </h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-white mb-4 pb-2 pb-md-3 pb-lg-2 mr-md-n3 pb-xl-3'>
              Para o Inter, simplificar também é incluir. Pensando nisso, criamos um canal de atendimento
              em Libras com tradução em tempo real.
            </p>
            <button
              className='btn btn-white text-orange--extra fs-14 text-none'
              onClick={() => {
                onCtaClick()
                sendDatalayerEvent(dataLayer)
              }}
            >
              Acessar videochamada em Libras
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

import React, { useEffect, useState } from 'react'
import * as S from './style'
import useWidth from 'src/hooks/window/useWidth'
import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { useDomReady } from '@interco/inter-ui'

const Validation = () => {
  const width = useWidth()
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  const videoUrl = 'https://static.bancointer.com.br/videos/libras/videofinal.mp4'
  const [ hasVideoEnded, setHasVideoEnded ] = useState(false)

  const videoElement = document.getElementById('libras-video') as HTMLVideoElement | null

  function videoEndedHandler () {
    if (!videoElement) return
    if (Math.floor(videoElement.currentTime) >= 46) {
      setHasVideoEnded(true)
    }
  }

  useEffect(() => {
    if (domReady) {
      if (videoElement) {
        videoElement.playbackRate = 1.25
        videoElement.addEventListener('timeupdate', videoEndedHandler, false)
      }
    }
  }, [ domReady ])

  const onClickHandler = () => {
    window.location.href = 'https://icom.app/inter'
    sendDatalayerEvent({
      section: 'dobra_01',
      element_action: 'click button',
      element_name: 'Acessar videochamada em Libras',
      section_name: 'Orientações importantes!',
      redirect_url: 'https://icom.app/inter',
    })
  }

  return (
    <S.Wrapper>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='fs-24 lh-28 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>Orientações importantes!</h1>
            <p className='fs-14 lh-18 fs-lg-18 lh-lg-22'>Para garantir uma boa experiência, preparamos algumas dicas! No vídeo abaixo vamos te explicar como funciona nosso atendimento em libras. Vamos começar!?</p>
          </div>
          <div className='col-12'>
            <video
              controls
              id='libras-video'
              width={width < 768 ? '100%' : '80%'}
              height='auto'
              preload='auto'
              muted
              autoPlay
              playsInline
            >
              <source src={videoUrl} type='video/mp4' />
            </video>
            <S.ButtonWrapper>
              <Button onClick={onClickHandler} fullWidth disabled={!hasVideoEnded}>
                Acessar videochamada em Libras
              </Button>
            </S.ButtonWrapper>
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}

export default Validation

import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { spacing24 } from 'src/styles/spacings'
import styled from 'styled-components'

export const Wrapper = styled.section`
  background-color: ${grayscale[100]};
  height: fit-content;

  @media ${device.tablet} {
    text-align: center;
  }
  h1 {
    font-family: 'Sora', sans-serif;
  }
`
export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 50%;
  height: fit-content;
  margin: 0 auto;
  margin-top: ${spacing24};
  button {
    text-align: center;
  }
`
